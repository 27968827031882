import { Dialog, Transition } from "@headlessui/react"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid"
import {
  Loading,
  Modal,
  Notification,
  SignUpTemplate,
  Stepper,
  TextInput,
} from "blixify-ui-web/lib"
import { Link, navigate } from "gatsby"
import React, { Component, createRef } from "react"
import { connect } from "react-redux"
import packageJson from "../../../package.json"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"
import {
  UserState,
  ageOption,
  cookFrequencyOption,
  familyMembersOption,
  genderOptions,
  professionOptions,
  tngStateOptions,
  tngUserTypeOptions,
} from "../../components/dashboard/AuthCard"
import PetronasLogo from "../../images/petronas_logo.png"
import ShellLogo from "../../images/shell_logo.png"
import {
  createAccount,
  createUserDetails,
  getUserInfoListener,
  signInWithGoogle,
  signInWithPhoneNumber,
  verifyOTP,
} from "../../store/actions/authActions"
import { updatePromotionCode } from "../../store/actions/promotionActions"
import { authStateInterface } from "../../store/reducers/authReducer"
import { promotionStateInterface } from "../../store/reducers/promotionReducer"
import { utilsStateInterface } from "../../store/reducers/utilsReducer"
import {
  DataInputComponent,
  handleChangeInput,
  renderModalInput,
} from "../../utils/dataInput"
import { utilPhonePlaceholder } from "../../utils/region"
import { NotificationState } from "./signIn"

interface Props {
  dispatch?: any
  dispatchChildren?: any
  authStore: authStateInterface
  utilsStore: utilsStateInterface
  promotionStore: promotionStateInterface
  updatePromotionCode: (promoCode: string) => void
}

interface State {
  otp: string
  loading: boolean
  userState: UserState
  provider: "google" | "phone" | "email" | ""
  notification: NotificationState | null
  stepIndex: number
}

class SignUp extends Component<Props> {
  state: State = {
    otp: "",
    loading: false,
    provider: "",
    notification: null,
    stepIndex: 0,
    userState: {
      email: "",
      password: "",
      name: "",
      mobileNo: "",
      gender: "",
      age: "",
      profession: "",
      professionString: "",
      familyMembers: "",
      cookFrequency: "",
      enterprise: "",
    },
  }
  profileFormRef: any = createRef()
  emailFormRef: any = createRef()
  otpInputRef: any = createRef()

  componentDidMount() {
    this.handleCheckAuthNavigator()
    const searchParams = new URLSearchParams(window.location.search)
    const promoCode = searchParams.get("promo")
    if (promoCode) {
      this.props.updatePromotionCode(promoCode)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading ||
      prevProps.authStore.userAuth !== this.props.authStore.userAuth ||
      prevProps.authStore.user !== this.props.authStore.user
    ) {
      this.handleCheckAuthNavigator()
    }
  }

  handleCheckAuthNavigator = async () => {
    if (this.props.authStore.userAuth) {
      if (!this.props.authStore.user) {
        this.handleChangeProvider("google")
        this.handleLoading(false)
      } else
        navigate(this.props.utilsStore.tngAccess ? "/tngHome" : "/dashboard")
    }
  }

  handleChangeProvider = (provider: string) => {
    this.setState({ provider, stepIndex: 0 })
  }

  handleChangeOTP = (e: any) => {
    this.setState({ otp: e.target.value })
  }

  handleUpdateUserState = (value: any) => {
    this.setState({
      userState: value,
    })
  }

  handleStepIndex = (index: number) => {
    this.setState({
      stepIndex: index,
    })
  }

  handleLoading = (loading: boolean) => {
    this.setState({ loading })
  }

  handleSubmit = async () => {
    try {
      if (this.state.stepIndex === 1) {
        this.handleLoading(true)
        if (this.state.provider === "phone") {
          const signInError = await verifyOTP(this.state.otp)
          if (signInError) {
            this.setState({
              notification: {
                type: false,
                title: "OTP Verification Failed",
                msg: "Your code may be incorrect, please try again",
              },
            })
          }
        } else if (this.state.provider === "email") {
          const promoCode = this.props.promotionStore.promotionCode
            ? this.props.promotionStore.promotionCode
            : ""
          const referralId = this.props.promotionStore.referralId ?? ""
          const creationError = await createAccount(
            this.state.userState,
            promoCode,
            referralId,
            this.props.utilsStore.firebase
          )
          if (creationError) {
            this.setState({
              notification: {
                type: false,
                title: "Account Creation Failed",
                msg: creationError,
              },
            })
          } else {
            this.setState({
              notification: {
                type: false,
                title: "Account Created Successfully",
                msg: "Redirecting you to dashboard",
              },
            })
          }
        }
        this.handleLoading(false)
      } else if (this.state.stepIndex === 0) {
        if (this.state.provider === "phone") {
          const signInError = await signInWithPhoneNumber(
            this.state.userState.mobileNo,
            this.props.utilsStore.firebase,
            this.props.utilsStore.recaptchaProvider
          )
          if (signInError) {
            this.setState({
              notification: {
                type: true,
                title: "Phone OTP Failed",
                msg: signInError,
              },
            })
          } else {
            this.handleStepIndex(this.state.stepIndex + 1)
            this.setState({
              notification: {
                type: true,
                title: "Phone OTP Sent",
                msg: "OTP has been sent to your phone number",
              },
            })
          }
        } else if (this.state.provider === "google") {
          this.handleLoading(true)
          const hasAuthToken = this.props.authStore.userAuth ? true : false
          if (hasAuthToken) {
            const promoCode = this.props.promotionStore.promotionCode
              ? this.props.promotionStore.promotionCode
              : ""
            const referralId = this.props.promotionStore.referralId ?? ""
            let creationError = await createUserDetails(
              this.state.userState,
              promoCode,
              referralId,
              this.props.utilsStore.firebase
            )
            if (creationError) {
              this.setState({
                notification: {
                  type: false,
                  title: "Account Creation Failed",
                  msg: creationError,
                },
              })
              this.handleLoading(false)
            } else {
              getUserInfoListener(
                this.props.dispatchChildren,
                this.props.utilsStore.tngAccess,
                this.props.utilsStore.firebase
              )
            }
          } else throw new Error("Error")
        } else {
          this.handleStepIndex(this.state.stepIndex + 1)
        }
      }
    } catch (err: any) {
      this.handleLoading(false)
      this.setState({
        notification: {
          type: false,
          title: "Unknown Error",
          msg: "Please contact info@arusoil.com if this issue exists again",
        },
      })
    }
  }

  renderPromoLanding = () => {
    if (
      this.props.promotionStore.promotionCode === "SHELL" ||
      this.props.promotionStore.promotionCode === "SHELLXARUSKL"
    ) {
      return (
        <div>
          <img className="h-16 mt-6 mb-2" src={ShellLogo} alt="Shell Logo" />
          <h3 className="text-gray-500 -mb-4">
            A collaboration that helps you recycle your used cooking oil (UCO)
            into biodiesel for the benefit of a greener future.
            {"\n"}
            <h3 className="font-bold mt-2 sm:mt-1">
              Sign up for an additional 10% in cashback when you recycle your
              UCO
            </h3>
          </h3>
        </div>
      )
    } else if (
      this.props.promotionStore.promotionCode.includes("GAS4OIL") ||
      this.props.promotionStore.promotionCode.includes("PETSB")
    ) {
      return (
        <div>
          <img
            className="h-16 mt-6 mb-2"
            src={PetronasLogo}
            alt="Petronas Logo"
          />
          <h3 className="text-gray-500 -mb-4">
            A collaboration that helps you recycle your used cooking oil (UCO)
            into biodiesel for the benefit of a greener future.
            {"\n"}
            <h3 className="font-bold mt-2 sm:mt-1">
              Sign up for an additional 20% in cashback when you recycle your
              UCO
            </h3>
          </h3>
        </div>
      )
    }
  }

  renderLeftComponents = () => {
    let steps = [
      {
        title: "Profile Information",
        description: "Help us to understand you better",
      },
    ]
    if (this.state.provider === "email") {
      steps.push({
        title: "Create Account",
        description: "Place your security credentials",
      })
      steps.push({
        title: "Verification",
        description: "Verify your email to ensure that you are contactable",
      })
    }
    if (this.state.provider === "phone") {
      steps = [
        {
          title: "Contact Information",
          description: "Fill In Your Contact",
        },
        {
          title: "OTP Verification",
          description: "Verify OTP sent to you via SMS",
        },
      ]
    }

    return (
      <>
        <h3 className="text-lg font-medium text-white">Create Your Account</h3>
        <Stepper
          steps={steps}
          disabled={true}
          className="mt-5"
          stepIndex={this.state.stepIndex}
          onChange={this.handleStepIndex}
          light={true}
        />
      </>
    )
  }

  renderNotification = () => {
    const notification = this.state.notification
    if (notification) {
      const correctIcon = (
        <CheckCircleIcon className="w-10 h-10 text-primary-600" />
      )
      const incorrectIcon = (
        <XCircleIcon className="w-10 h-10 text-primary-600" />
      )
      return (
        <Notification
          icon={notification.type ? correctIcon : incorrectIcon}
          notificationAttribute={{
            title: notification.title,
            desc: notification.msg,
            visible: true,
          }}
          lib={{
            Transition,
          }}
          onClose={() => {
            this.setState({ notification: null })
            return ""
          }}
        />
      )
    }
    return null
  }

  renderSteps = () => {
    const steps = []

    const inputChangeParam = {
      state: this.state.userState,
      exclude: [],
    }
    let profileDataInputComponents: DataInputComponent[] = [
      {
        id: "name",
        type: "textInput",
        label: "Name",
        placeholder: "Enter your name",
        value: this.state.userState.name,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
      {
        id: "mobileNo",
        type: "phoneInput",
        label: "Phone Number",
        placeholder: utilPhonePlaceholder(),
        value: this.state.userState.mobileNo,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
      {
        id: "gender",
        type: "selectInput",
        label: "Gender (Optional)",
        value: this.state.userState.gender,
        options: genderOptions,
        optional: true,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
      {
        id: "age",
        type: "selectInput",
        label: "Age (Optional)",
        value: this.state.userState.age,
        options: ageOption,
        optional: true,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
      {
        id: "profession",
        type: "selectInput",
        label: "Profession (Optional)",
        value: this.state.userState.profession,
        options: professionOptions,
        optional: true,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
      {
        id: "familyMembers",
        type: "selectInput",
        label: "Family Members (Optional)",
        value: this.state.userState.familyMembers,
        options: familyMembersOption,
        optional: true,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
      {
        id: "cookFrequency",
        type: "selectInput",
        label: "Cook Frequency (Optional)",
        value: this.state.userState.cookFrequency,
        options: cookFrequencyOption,
        optional: true,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
    ]
    const emailDataInputComponents: DataInputComponent[] = [
      {
        id: "email",
        type: "emailInput",
        label: "Email",
        placeholder: "Enter your email",
        value: this.state.userState.email,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
      {
        id: "password",
        type: "passwordInput",
        label: "Password",
        placeholder: "Enter your password",
        value: this.state.userState.password,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      },
    ]

    if (this.state.provider === "phone") {
      profileDataInputComponents = [profileDataInputComponents[1]]
    } else if (this.state.provider === "google") {
      const hasPhoneNumber = this.props.authStore.userAuth.phoneNumber
      if (hasPhoneNumber) {
        profileDataInputComponents.splice(1, 1)
      }
    } else if (this.props.utilsStore.tngAccess) {
      profileDataInputComponents.splice(2, 5)
      profileDataInputComponents.push({
        id: "tngUserType",
        type: "selectInput",
        label: "Type of User",
        value: this.state.userState.tngUserType ?? "",
        options: tngUserTypeOptions,
        optional: true,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      })
      profileDataInputComponents.push({
        id: "tngState",
        type: "selectInput",
        label: "State",
        value: this.state.userState.tngState ?? "",
        options: tngStateOptions,
        optional: true,
        onChange: e => {
          handleChangeInput(e, inputChangeParam, this.handleUpdateUserState)
        },
      })
    }
    const profileFormInputRefs = profileDataInputComponents.map(() =>
      createRef()
    )
    const profileComponentView: any = []
    profileDataInputComponents.map((item, index) =>
      profileComponentView.push(
        renderModalInput(item, profileFormInputRefs[index], index)
      )
    )

    if (this.state.provider === "phone") {
      profileComponentView.push(
        <div id="recaptcha-container" className="my-2 w-full" />
      )
    }

    steps.push({
      components: profileComponentView,
      formRef: this.profileFormRef,
      refs: profileFormInputRefs,
      onHandleComponents: () => {
        this.profileFormRef.current?.handleSubmit()
      },
    })

    const emailFormInputRefs = emailDataInputComponents.map(() => createRef())
    const emailComponentView: any = []
    emailDataInputComponents.map((item, index) =>
      emailComponentView.push(
        renderModalInput(item, emailFormInputRefs[index], index)
      )
    )

    if (this.state.provider === "email") {
      steps.push({
        components: emailComponentView,
        formRef: this.emailFormRef,
        refs: emailFormInputRefs,
        onHandleComponents: () => {
          this.emailFormRef.current?.handleSubmit()
        },
      })
    } else if (this.state.provider === "phone") {
      steps.push({
        components: [
          <TextInput
            ref={this.otpInputRef}
            id="otp"
            value={this.state.otp}
            type="regex"
            label="OTP Code"
            placeholder="123456"
            autoComplete="off"
            onChange={this.handleChangeOTP}
            pattern={RegExp("^[0-9]{6,6}$")}
          />,
        ],
        formRef: this.emailFormRef,
        refs: [this.otpInputRef],
        onHandleComponents: () => {
          this.emailFormRef.current?.handleSubmit()
        },
      })
    }

    return steps
  }

  renderPreventBack = () => {
    if (this.state.provider === "google") {
      return true
    } else if (this.state.provider === "phone" && this.state.stepIndex === 1) {
      return true
    } else {
      return false
    }
  }

  render() {
    if (this.props.authStore.userLoading) {
      return null
    }

    return (
      <div className="bg-warm-gray-50">
        <SEO
          title="Arus Oil - Sign Up"
          description="Sign up an account in Arus Oil now. This allows you to view daily purchase rates of used cooking oil,contact logistic for collection and view payment made to you."
        />
        <Header page="Sign Up" />
        <Modal
          open={this.state.loading}
          lib={{
            Dialog,
            Transition,
          }}
          renderContent={() => {
            return (
              <div className="flex flex-col items-center">
                <Loading />
                <p className="text-sm text-gray-700 mt-2">
                  Please hold on while we are verifying your account
                </p>
              </div>
            )
          }}
          onClose={() => {}}
        />
        {this.renderNotification()}
        <main className="overflow-hidden pb-16">
          <div className="bg-warm-gray-50">
            <div className="py-12">
              <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                <h1 className="mb-2 text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl">
                  Sign Up
                </h1>
                <Link
                  to="/auth/signIn"
                  className="font-bold text-lg text-primary-600 hover:text-primary-500"
                >
                  Have An Account ?
                </Link>
                {this.renderPromoLanding()}
              </div>
            </div>
          </div>

          <div className="mb-12 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-white">
              <SignUpTemplate
                type={this.state.provider}
                preventBack={this.renderPreventBack()}
                stepIndex={this.state.stepIndex}
                leftComponents={this.renderLeftComponents()}
                leftClassName="bg-gradient-to-b from-arusgreen-500 to-arusgreen-600"
                defaultProvider="email"
                handleUpdateStepIndex={this.handleStepIndex}
                onChangeProvider={async (provider: string) => {
                  if (provider === "google") {
                    this.handleLoading(true)
                    await signInWithGoogle(
                      this.props.utilsStore.firebase,
                      this.props.utilsStore.googleAuthProvider
                    )
                    this.handleLoading(false)
                  } else {
                    this.handleChangeProvider(provider)
                  }
                }}
                onCompleteEachStep={this.handleSubmit}
                provider={
                  this.props.utilsStore.tngAccess
                    ? ["email"]
                    : ["email", "google", "phone"]
                }
                formList={{
                  steps: this.renderSteps(),
                }}
                version={packageJson.version}
              />
            </div>
          </div>
        </main>
        {!this.props.utilsStore.tngAccess && <Footer />}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
    promotionStore: state.promotionStore,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updatePromotionCode: (promoCode: string) =>
      dispatch(updatePromotionCode(promoCode)),
  }
}

const CustomSignUp = connect(mapStateToProps, mapDispatchToProps)(SignUp)

class SignUpWrapper extends Component<Props> {
  render() {
    return <CustomSignUp dispatchChildren={this.props.dispatch} />
  }
}

export default connect(mapStateToProps)(SignUpWrapper)
