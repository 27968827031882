import axios from "axios"
import {
  Address,
  AddressClass,
  InputDatePicker,
  Select,
  TextInput,
  UploadInput,
} from "blixify-ui-web/lib"
import React from "react"

export interface DataInputComponent {
  id: string
  type: string
  label: string
  value: string | string[] | AddressClass | Date | File | number | boolean
  class?: string
  placeholder?: string
  options?: any[]
  optional?: boolean
  disabled?: boolean
  autoComplete?: "on" | "off"
  minDate?: boolean
  onChange: (e: any) => void
  onSearch?: (searchText: string) => void
}

export const handleCloneDataInput = ({
  state,
  exclude,
}: {
  state: any
  exclude?: string[]
}) => {
  const dataInputDetail = JSON.parse(JSON.stringify(state))
  if (exclude && exclude.length > 0) {
    exclude.map(eachExclude => {
      dataInputDetail[eachExclude] = state[eachExclude as any]
      return null
    })
  }
  return dataInputDetail
}

export const handleUploadChangeImage = (
  e: any,
  oriState: { state: any; exclude?: string[] },
  hook: any
) => {
  const dummyInput = handleCloneDataInput(oriState)
  if (e.target.files.length > 0) {
    dummyInput[e.target.id] = e.target.files[0]
  } else {
    dummyInput[e.target.id] = ""
  }
  hook(dummyInput)
}

export const handleChangeInput = (
  e: any,
  oriState: { state: any; exclude?: string[] },
  hook: any,
  objectId?: string
) => {
  const dummyInput = handleCloneDataInput(oriState)
  const valueText = e.target.value
  if (objectId) {
    dummyInput[objectId][e.target.id] = valueText
  } else {
    dummyInput[e.target.id] = valueText
  }
  hook(dummyInput)
}

export const renderModalInput = (
  item: DataInputComponent,
  ref: any,
  index: number
) => {
  const label = item.label
  switch (item.type) {
    case "textInput":
    case "numberInput":
    case "phoneInput":
    case "emailInput":
    case "passwordInput":
      let itemType = "text"
      if (item.type === "numberInput") {
        itemType = "number"
      } else if (item.type === "phoneInput") {
        itemType = "mobile"
      } else if (item.type === "emailInput") {
        itemType = "email"
      } else if (item.type === "passwordInput") {
        itemType = "password"
      }
      return (
        <TextInput
          id={item.id}
          ref={item.optional ? {} : ref}
          key={index}
          value={item.value}
          label={label}
          containerClassName={item.class}
          type={itemType}
          autoComplete={item.autoComplete}
          placeholder={item.placeholder ?? ""}
          onChange={item.onChange}
          disabled={item.disabled ?? false}
        />
      )
    case "selectInput":
      return (
        <Select
          id={item.id}
          ref={item.optional ? {} : ref}
          value={item.value as string}
          label={label}
          containerClassName={item.class}
          options={item.options ?? []}
          disabled={item.disabled ?? false}
          onChange={(value: any) => {
            const e = {
              target: {
                id: item.id,
                value: value,
              },
            }
            item.onChange(e)
          }}
          onSearch={item.onSearch}
        />
      )
    case "addressInput":
      return (
        <Address
          id={item.id}
          ref={item.optional ? {} : ref}
          value={item.value as AddressClass}
          label={label}
          className={item.class}
          onChange={(value: any) => {
            const e = {
              target: {
                id: item.id,
                value: value,
              },
            }
            item.onChange(e)
          }}
        />
      )
    case "imageInput":
    case "fileInput":
      return (
        <UploadInput
          lib={{
            axios: axios,
          }}
          id={item.id}
          ref={item.optional ? {} : ref}
          label={label}
          file={item.value}
          className={item.class}
          accept={item.type === "fileInput" ? ".pdf" : ".png,.jpg,.jpeg"}
          fileDescription={
            item.type === "fileInput" ? "PDF up to 10MB" : undefined
          }
          hidePreview={item.type === "fileInput" ? true : false}
          onChange={item.onChange}
        />
      )
    case "dateInput":
      return (
        <InputDatePicker
          id={item.id}
          ref={item.optional ? {} : ref}
          minDate={item.minDate ? new Date() : undefined}
          className={item.class}
          onChange={(id: string, value?: Date) => {
            const e = {
              target: {
                id: id,
                value: value,
              },
            }
            item.onChange(e)
          }}
          value={item.value as any}
        />
      )
    default:
      return null
  }
}
